import React, {useEffect} from 'react'
import Layout from '../layouts/Layout'

export const RedirectionTemplate = context => {
  const {pageContext: { redirectTo }} = context

  useEffect(() => {
    window.location.replace(redirectTo);
  }, [])

  return (
    <></>
  )
}

export default RedirectionTemplate
